<template>
    <input ref="elRef" v-model="model" type="text">
</template>

<script lang="ts">
import type { DataColumn } from 'o365-datagrid';

export interface IProps {
    column?: DataColumn,
};
</script>


<script setup lang="ts">
import { ref } from 'vue';

defineProps<IProps>();

const model = defineModel<string>();
const elRef = ref<HTMLInputElement>();

function activateEditor(pOptions) {
    elRef.value?.focus();
    if (elRef.value && pOptions?.selectionRange) {
        elRef.value.setSelectionRange(pOptions.selectionRange[0], pOptions.selectionRange[1])
        elRef.value.scrollLeft = 0
    }
}

defineExpose({ elRef, activateEditor });
</script>